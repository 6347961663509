import { MockedProvider } from "@apollo/client/testing";
import { within, userEvent } from "@storybook/test";
import {
  GET_LANDING_PAGE_QUERY,
  GET_CONTRACTS_QUERY,
} from "components/pages/LandingPages/data/queries";
import HostnameProvider from "lib/HostnameProvider";
import LandingPageEdit from "../LandingPageEdit";
import SampleLandingPage from "test_support/data/SampleLandingPage";
import SampleContracts from "test_support/data/SampleContracts";
import { cloneDeep, set } from "lodash-es";
import OutletContainer from "components/layout/OutletContainer/OutletContainer";

export default {
  title: "Pages/LandingPageEditMulti/Questions_versions",
  component: LandingPageEdit,
  parameters: {
    zeplinLink: "https://zpl.io/YYdW51k",
  },
};

const Template = (args, { parameters }) => {
  return (
    <MockedProvider mocks={parameters.mocks} addTypename={true}>
      <HostnameProvider portalHostname={'dev.yourdreamdegree.com'} defaultLeadsHostname='unknown' complyedHostname='unknown'>
        <OutletContainer>
          <LandingPageEdit {...args} />
        </OutletContainer>
      </HostnameProvider>
    </MockedProvider>
  );
};

export const ExpandedQuestions = Template.bind({});
ExpandedQuestions.args = {};

ExpandedQuestions.parameters = {
  orbit: {
    user: {
      permitGlobalAdmin: true,
      accessibleVendorIds: ['1'],
    },
    records: [
      {
        type: "vendors",
        id: "1",
        attributes: {
          id: 1,
          name: "Cygnus Education",
        },
      },
    ],
  },
  reactRouter: {
    routePath: "/landing-pages/:landingPageId/*",
    routeParams: { landingPageId: "d5a493a9" },
    browserPath: "/landing-pages/d5a493a9/questions",
  },
  mocks: [
    {
      request: {
        query: GET_LANDING_PAGE_QUERY,
        variables: {
          id: "d5a493a9",
        },
      },
      result: {
        data: {
          landingPage: SampleLandingPage,
        },
      },
    },
    {
      request: {
        query: GET_CONTRACTS_QUERY,
        variables: {
          clientIds: ["188", "29", "184", "195"],
          vendorId: "1",
        },
      },
      result: {
        data: {
          contracts: SampleContracts,
        },
      },
    },
  ],
};

ExpandedQuestions.play = async ({ canvasElement }) => {
  const step2 = await within(canvasElement).findByRole("group", {
    name: /Step 2/,
  });
  const expandButton = await within(step2).findByRole("button", {
    name: /expand/i,
  });

  await userEvent.click(expandButton);
};

export const QuestionsWithPersistedErrors = Template.bind({});
QuestionsWithPersistedErrors.args = {};

// workaround for overly aggressive caching in suspendedQuery
// create mock request with different variables
let errorParams = cloneDeep(SampleLandingPage);
set(errorParams, "steps[0].questions[0].text", "");
QuestionsWithPersistedErrors.parameters = {
  ...ExpandedQuestions.parameters,
  reactRouter: {
    routePath: "/landing-pages/:landingPageId/*",
    routeParams: { landingPageId: "d5a493a9-error" },
    browserPath: "/landing-pages/d5a493a9-error/questions",
  },
  mocks: [
    {
      request: {
        query: GET_LANDING_PAGE_QUERY,
        variables: {
          id: "d5a493a9-error",
        },
      },
      result: {
        data: {
          landingPage: errorParams,
        },
      },
    },
    {
      request: {
        query: GET_CONTRACTS_QUERY,
        variables: {
          clientIds: ["188", "29", "184", "195"],
          vendorId: "1",
        },
      },
      result: {
        data: {
          contracts: SampleContracts,
        },
      },
    },
  ],
};
