import { useState, useEffect, useMemo, useCallback } from "react"
import { Paper, Box, Grid, Typography, useTheme, Stack, IconButton } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add'
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate'
import Accordion from '../../../Accordion'
import AccordionPanel from '../../../AccordionPanel'
import Suspenseful from '../../../Suspenseful'
import FooterBar from '../../../UI/FooterBar'

import LandingPagesResource from '../../../../resources/LandingPagesResource'
import { failOnHttpError, stripHTML } from '../../../../lib/utils'
import COLORS from '../../../../lib/colors'
import SectionTitle from "components/UI/SectionTitle"
import PortalProgramSelectionModal from "../shared/PortalProgramSelectionModal"
import { buildLandingPage } from "../data/helpers"
import { CancelOrConfirmDialog } from "../shared/CancelOrConfirmDialogs"
import { useHostname } from "lib/HostnameProvider"
import MainContainer from "components/layout/MainContainer/MainContainer"
import BookendsProvider, { useBookends } from "lib/BookendsProvider"

import type { InitializedRecord } from '@orbit/records'
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { openInNewTab } from "../data/utils"
import { useNotifications } from "lib/NotificationsProvider"
import { useOrbit } from "providers/OrbitProvider"

function ListOfAccordionLandingPages({groupedLandingPages}){
  return (
    <Accordion>
      {Object.keys(groupedLandingPages || {}).sort((a,b) => a === 'None' ? 1 : b === 'None' ? -1 : a.localeCompare(b)).map(clientName => (
        <AccordionPanel key={clientName + "_branded"} label={stripHTML(clientName)} panelId={clientName}>
          {(groupedLandingPages[clientName] || []).sort((a, b) => a.name.localeCompare(b.name)).map(landingPage => (
            <LandingPageLink key={landingPage.publicId + "_landing_page_link"} landingPage={landingPage} />
          ))}
        </AccordionPanel>
      ))}
    </Accordion>
  )
}

function ListOfLandingPages({groupedLandingPages}) {
  return (
    <>
      <Accordion>
        <AccordionPanel key="portalAccordianPanel" label="Portals" panelId="portalAccordianPanel">
          {groupedLandingPages.sort((a, b) => a.name.localeCompare(b.name)).map( landingPage => (
            <LandingPageLink key={landingPage.publicId + "_landing_page_link"} landingPage={landingPage} />
          ))}
        </AccordionPanel>
      </Accordion>
    </>
  )
}

function LandingPageLink({landingPage}){
  const theme = useTheme()
  const navigate = useNavigate()
  const { addNotification } = useNotifications()

  const handleCopy = (e) => {
    e.preventDefault()
    if (e.metaKey || e.ctrlKey) {
      e.stopPropagation()
      openInNewTab(landingPageLinkUrl(landingPage))
    }
  }

  const handleDuplicate = (e) => {
    e.preventDefault()

    fetch(`/api/v1/landing-pages/${landingPage.publicId}/duplicate`, { method: 'POST' })
      .then(failOnHttpError)
      .then(response => {
        response.json()
        .then(data => {
          navigate(`/landing-pages/${data.id}`)
          addNotification({variant: 'notice', message: 'LandingPage duplicated'})
        })
      })
      .catch(_error => {
        addNotification({variant: 'alert', message: 'Error duplicating LandingPage'})
      })
  }

  return(
    <Link
      key={landingPage.publicId + "_link"}
      to={`/landing-pages/${landingPage.publicId}`}
      style={{
        textDecoration: 'none',
        color: theme.palette.text.primary,
      }}
    >
      <Grid
        container
        direction="row"
        sx={{
          borderTop: `1px solid ${COLORS.veryLightGray}`,
          padding: "0px 24px",
          alignItems: 'center',
          height: '48px',
          '&:not(:hover) .showOnHover': {visibility: 'hidden'}
        }}
      >
        <Grid item sm={12} md={6} style={{ overflowWrap: 'anywhere' }}>
          {stripHTML(landingPage.name)}
        </Grid>
        <Grid item sm={12} md={6}>
          <Stack direction='row' sx={{ alignItems: 'center', gap: 1 }}>
            <span style={{ color: theme.palette.text.secondary, overflowWrap: 'anywhere' }}>
              {landingPageLinkUrl(landingPage)}
            </span>

            <CopyToClipboard className='showOnHover' text={landingPageLinkUrl(landingPage)}>
              <IconButton aria-label="Copy URL" onClickCapture={handleCopy} sx={{ width: '30px', height: '30px' }}>
                <ContentCopyRoundedIcon sx={{ color: COLORS.slateGray }}/>
              </IconButton>
            </CopyToClipboard>

            <IconButton className='showOnHover' aria-label="Duplicate LandingPage" onClick={handleDuplicate} sx={{ width: '30px', height: '30px' }}>
              <ControlPointDuplicateIcon sx={{ color: COLORS.slateGray }}/>
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    </Link>
  )
}

function landingPageLinkUrl(landingPage){
  const url = `https://${landingPage.hostname}/${landingPage.path}`
  return(url === `https:///` ? "" : url)
}

function LandingPagesSection({title, addButtonLabel, onClickAdd, children}) {
  return (
    <>
      <SectionTitle
        title={title}
        controls={[{icon: <AddIcon/>, onClick: onClickAdd, label: addButtonLabel}]}
        isInCard={false}
      />

      <Box sx={{ marginTop: '12px', marginBottom: '48px' }}>
        {children}
      </Box>
    </>
  )
}

interface GroupedPages {
  singleClient: Record<string,InitializedRecord[]>
  multiClient: InitializedRecord[]
}

function LandingPages({ resource }) {
  const landingPages = resource.get()
  const { store } = useOrbit()
  const [newLandingPage, setNewLandingPage] = useState<Record<string,unknown>|null>(null)
  const { portalHostname: defaultPortalHostname } = useHostname()
  const { setBookends } = useBookends()

  const navigate = useNavigate()
  const [confirmableCallback, setConfirmableCallback] = useState<(() => void) | null>(null)

  const handleProgramModalComplete = useCallback(landingPage => {
    navigate('new', { state: { landingPage } })
  }, [navigate])

  const handleClose = useCallback((_event, reason) => {
    if(reason === 'backdropClick') { return }
    setConfirmableCallback((_prev) => () => { setNewLandingPage(null) })
  },[])

  const groupedPages = useMemo(() => {
    const groupedPages: GroupedPages = {
      singleClient: {},
      multiClient: [],
    }

    landingPages.forEach(landingPage => {
      const template = store.cache.query(q => q.findRelatedRecord(landingPage, 'template')) as InitializedRecord
      if(template?.attributes?.multiClient) {
        groupedPages.multiClient.push(landingPage.attributes)
      }
      else {
        const brandedPages = groupedPages.singleClient || {}
        const primaryClient = store.cache.query(q => q.findRelatedRecord(landingPage, 'primaryClient')) as InitializedRecord
        const groupedClientHeading = primaryClient ? (primaryClient.attributes?.internalName || primaryClient.attributes?.name) : 'None'
        const groupedClientPages = brandedPages[groupedClientHeading] || []

        groupedClientPages.push(landingPage.attributes)
        brandedPages[groupedClientHeading] = groupedClientPages
        groupedPages.singleClient = brandedPages
      }
    })
    return groupedPages
  }, [landingPages, store])

  const handleAddLandingPage = useCallback(template => {
    const routes = [ { id: null, path: '', hostname: defaultPortalHostname } ]
    setNewLandingPage(buildLandingPage({
      template: { id: template.id, name: template.attributes.name, multiClient: template.attributes.multiClient },
      routes: routes
    }))
  }, [defaultPortalHostname])
  const templates = store.cache.query(q => q.findRecords('template')) as InitializedRecord[]
  const portalTemplate = templates.find(template => template.attributes?.multiClient)

  useEffect(() => {
    setBookends({
      footer: {
        buttons: [
          {
            type: 'menu',
            label: 'Add new',
            items: [
              { text: 'Portal', onClick: () => handleAddLandingPage(portalTemplate) },
              { text: 'Branded', onClick: () => { /* TODO */ } }
            ],
          },
        ],
      },
    })
  }, [setBookends, handleAddLandingPage, portalTemplate])

  return (
    <>
      <MainContainer>
        <Paper
          sx={{
            padding: (theme) => theme.spacing(2, 3),
            boxShadow: 1,
          }}
        >
          <Typography variant='h1'>
            Landing Page Management
          </Typography>
          <Box
            sx={{
              marginTop: '24px',
              '.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters:has(+ .Mui-expanded)': {
                borderBottomLeftRadius: '10px',
                borderBottomRightRadius: '10px'
              },
              '.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters:has(.Mui-expanded)': {
                borderRadius: '10px'
              },
              '.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters.Mui-expanded + .MuiAccordion-root:not(.Mui-expanded)': {
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px'
              },
              '.MuiAccordionSummary-content': {
                '.MuiTypography-root': {
                  color: 'text.primary'
                }
              },
              '.MuiAccordionDetails-root': {
                padding: 0
              }
            }}
          >
            <LandingPagesSection title="Unbranded" addButtonLabel="Add Portal" onClickAdd={() => handleAddLandingPage(portalTemplate)}>
              <ListOfLandingPages groupedLandingPages={groupedPages.multiClient} />
            </LandingPagesSection>

            <LandingPagesSection title="Branded" addButtonLabel="Add Landing Page" onClickAdd={() => console.log('Branded Add click')}>
              <ListOfAccordionLandingPages groupedLandingPages={groupedPages.singleClient} />
            </LandingPagesSection>
          </Box>
        </Paper>
      </MainContainer>

      <FooterBar/>

      {newLandingPage && (
        <PortalProgramSelectionModal
          onClose={handleClose}
          onComplete={handleProgramModalComplete}
          landingPage={newLandingPage}
        />
      )}

      <CancelOrConfirmDialog
        confirmableCallback={confirmableCallback}
        setConfirmableCallback={setConfirmableCallback}
      />
    </>
  )
}

export default function LandingPagesWrapper() {
  const { remote } = useOrbit()
  const resource = useMemo(() => new LandingPagesResource({ remote }), [remote])
  return (
    <BookendsProvider>
      <Suspenseful
        component={LandingPages}
        resource={resource}
      />
    </BookendsProvider>
  )
}
