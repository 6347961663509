import moment from '../lib/moment'
import { generateRange } from '../lib/utils'

import PromisedResource from './PromisedResource'

function getDailyCapFor(store, dailyCaps, clientCampaignGroup, year, month, day) {
  return dailyCaps
    .filter(dailyCap => store.cache.query(q => q.findRelatedRecord(dailyCap, 'clientCampaignGroup'))?.id === clientCampaignGroup.id)
    .filter(dailyCap => dailyCap.attributes.year === year)
    .filter(dailyCap => dailyCap.attributes.month === month)
    .filter(dailyCap => dailyCap.attributes.day === day)[0]
}

function buildDailyCap({clientCampaignGroup, year, month, day}) {
  const newCap = {
    type: 'dailyCap',
    relationships: {
      clientCampaignGroup: { data: clientCampaignGroup },
    },
    attributes: {
      year: year,
      month: month,
      day: day,
      limit: '',
    }
  }
  return newCap
}

function newDailyCapFor(store, clientCampaignGroup, year, month, day) {
  return store.update(q => q.addRecord(buildDailyCap({clientCampaignGroup, year, month, day})))
}

function findOrBuildDailyCaps(store, dailyCaps, clientCampaignGroup, year, month) {
  const daysInMonth = moment([year, month-1]).daysInMonth()
  return [...generateRange(1, daysInMonth)].map(day => getDailyCapFor(store, dailyCaps, clientCampaignGroup, year, month, day) || newDailyCapFor(store, clientCampaignGroup, year, month, day))
}

export default class DailyCapsCalendarResource extends PromisedResource {
  constructor(store, dailyCapsResource, clientCampaignGroup, year, month) {
    super(clientCampaignGroup ?
      dailyCapsResource.promise.then(dailyCaps => Promise.all(findOrBuildDailyCaps(store, dailyCaps, clientCampaignGroup, year, month))) :
      new Promise(() => {})) // Block indefinitely if clientCampaignGroup is null
    this.dailyCapsResource = dailyCapsResource
  }
}
