import { getIn } from '../components/Formik/forms'
import { getRemoteId } from './DataModel'

export const userIsGlobalAdmin  = ({currentUser}) => getIn(currentUser, 'attributes.permitGlobalAdmin')
export const organizationIsClient = ({currentUser}) => !userIsGlobalAdmin({currentUser}) && !!(getIn(currentUser, 'relationships.clients.data').length > 0)
export const organizationIsVendor = ({currentUser}) => !userIsGlobalAdmin({currentUser}) && !!(getIn(currentUser, 'relationships.vendors.data').length > 0)
export const userIsAdmin  = ({currentUser}) => getIn(currentUser, 'attributes.permitOrganizationAdmin')
export const someContractCanPostLeads = ({accessibleVendorContracts}) => accessibleVendorContracts.some(c => !!getIn(c, 'attributes.canPostLeads'))
export const or = (...callbacks) => (...args) => callbacks.some(callback => callback(...args))
export const and = (...callbacks) => (...args) => !callbacks.some(callback => !callback(...args))

function singleClientUser(currentUser) {
  return currentUser.relationships.clients.data.length == 1 && currentUser.relationships.vendorContracts.data.length == 0
}

function singleContractUser(currentUser) {
  return currentUser.relationships.vendorContracts.data.length == 1 && currentUser.relationships.clients.data.length == 0
}

export function getDefaultScope(currentUser) {
  if(singleClientUser(currentUser)) {
    const client = currentUser.relationships.clients.data[0]
    const clientId = getRemoteId(client)
    return `/clients/${clientId}`
  } else if(singleContractUser(currentUser)) {
    const contract = currentUser.relationships.vendorContracts.data[0]
    const contractId = getRemoteId(contract)
    return `/contracts/${contractId}`
  }

  return ''
}
