import { useCallback, useMemo, useState, useRef } from 'react'
import moment from '../../lib/moment'
import ClientCampaignGroupForm from './ClientCampaignGroupForm'
import DailyCapHeader from './DailyCapHeader'
import DailyCapBody from './DailyCapBody'
import ClientCampaignGroupsResource from '../../resources/ClientCampaignGroupsResource'
import DailyCapsCalendarResource from '../../resources/DailyCapsCalendarResource'
import DailyCapsResource from '../../resources/DailyCapsResource'
import PromisedResource from '../../resources/PromisedResource'

import Box from '@mui/material/Box'

import { isNew, newRecord } from '../../lib/DataModel'
import { getIn } from 'formik'
import { useRefreshable } from '../../lib/hooks'

import { sortBy, titlecase } from '../../lib/utils'
import MultiStepModal from '../UI/MultiStepModal'
import { CAMPAIGN_TYPES_VALUES } from 'components/pages/LandingPages/data/constants'
import { useOrbit } from 'providers/OrbitProvider'
import { InitializedRecord } from '@orbit/records'
import { DailyCapFormHandle } from './DailyCapForm'

const defaultClientCampaignGroups = CAMPAIGN_TYPES_VALUES

function newClientCampaignGroup(contract, campaignType, id) {
  return newRecord({
    type: 'clientCampaignGroup',
    id: id,
    attributes: {
      name: titlecase(campaignType),
      clientCampaignIds: [],
      campaignType: campaignType,
    },
    relationships: {
      contract,
    },
  })
}

const initializeMissingDefaultGroups = (contract, clientCampaignGroups) => {
  const defaultGroups = []

  defaultClientCampaignGroups.forEach((campaignType) => {
    if(!clientCampaignGroups.some((clientCampaignGroup) => getIn(clientCampaignGroup, 'attributes.campaignType') === campaignType)) {
      defaultGroups.push(newClientCampaignGroup(contract, campaignType, `prepopulated-${campaignType}`))
    }
  })

  return defaultGroups
}

class ClientCampaignGroupsResourceWithDefaults extends PromisedResource<InitializedRecord[]> {
  constructor({ remote, contract }) {
    super(new ClientCampaignGroupsResource({ remote, contract }).promise.then(items => {
      const defaultGroups = initializeMissingDefaultGroups(contract, items)
      return sortBy([...defaultGroups, ...items], 'attributes.campaignType', 'attributes.name')
    }))
  }
}

function DailyCapModalBody({
  dailyCapsCalendarResource,
  clientCampaignGroupsResource,
  selectedClientCampaignGroup,
  setSelectedClientCampaignGroup,
  dailyCapsResource,
  setDailyCapsResource,
  campaignCaps,
  onClickEdit,
  onClickAdd,
  formDirty,
  setFormDirty,
  onClose
}) {
  const childRef = useRef<DailyCapFormHandle>()
  const [capsEmpty, setCapsEmpty] = useState(true)

  const onClickClear = useCallback(() => {
    childRef.current?.clearCaps()
  }, [childRef])

  return (
    <Box sx={{ width: 582 }}>
      <DailyCapHeader
        clientCampaignGroupsResource={clientCampaignGroupsResource}
        selectedClientCampaignGroup={selectedClientCampaignGroup}
        setSelectedClientCampaignGroup={setSelectedClientCampaignGroup}
        onClickEdit={onClickEdit}
        onClickAdd={onClickAdd}
        onClickClear={onClickClear}
        disabled={formDirty}
        capsEmpty={capsEmpty}
        dailyCapsResource={dailyCapsResource}
      />
      <DailyCapBody
        dailyCapsCalendarResource={dailyCapsCalendarResource}
        clientCampaignGroup={selectedClientCampaignGroup}
        setClientCampaignGroup={setSelectedClientCampaignGroup}
        dailyCapsResource={dailyCapsResource}
        setDailyCapsResource={setDailyCapsResource}
        campaignCaps={campaignCaps}
        setFormDirty={setFormDirty}
        onClose={onClose}
        setCapsEmpty={setCapsEmpty}
        ref={childRef}
      />
    </Box>
  )
}

export default function DailyCapModal({
  contract,
  clientCampaignsResource,
  onClose,
  campaignCaps,
  month
}) {
  const { remote, store } = useOrbit()
  const [formDirty, setFormDirty] = useState(false)
  const [editingClientCampaignGroup, setEditingClientCampaignGroup] = useState(null)
  const [selectedClientCampaignGroup, setSelectedClientCampaignGroup] = useState(null)
  const [clientCampaignGroupsResource, refreshClientCampaignGroups] = useRefreshable((remote, contract) => new ClientCampaignGroupsResourceWithDefaults({ remote, contract }), [remote, contract])

  const [dailyCapsResource, setDailyCapsResource] = useState(new DailyCapsResource({remote, contract, year: month.year(), month: month.month()+1}))

  const dailyCapsCalendarResource = useMemo(() => {
    if(dailyCapsResource && selectedClientCampaignGroup) {
      return new DailyCapsCalendarResource(store, dailyCapsResource, selectedClientCampaignGroup, dailyCapsResource.year, dailyCapsResource.month)
    }
  }, [dailyCapsResource, selectedClientCampaignGroup, store])

  const monthFormatted = useMemo(() => moment([dailyCapsResource.year, dailyCapsResource.month-1]).format('MMMM YYYY'), [dailyCapsResource])

  const title = useMemo(() => {
    if(editingClientCampaignGroup) {
      return isNew(editingClientCampaignGroup) ? 'Add New Campaign Group' : 'Edit Campaign Group'
    } else {
      return `Daily Caps - ${monthFormatted}`
    }
  }, [monthFormatted, editingClientCampaignGroup])

  const handleClose = useCallback(() => {
    if(!formDirty || confirm('You have unsaved changes. Are you sure you want to leave without saving?')) {
      onClose()
    }
  }, [onClose, formDirty])

  const handleClickAdd = useCallback(() => {
    return setEditingClientCampaignGroup(newClientCampaignGroup(contract))
  }, [setEditingClientCampaignGroup, contract])

  const handleClickEdit = useCallback(() => {
    setEditingClientCampaignGroup(selectedClientCampaignGroup)
  }, [selectedClientCampaignGroup, setEditingClientCampaignGroup])

  const handleClickBack = useCallback(() => {
    setEditingClientCampaignGroup(null)
  }, [setEditingClientCampaignGroup])

  const handleSaveOrDelete = useCallback((clientCampaignGroup) => (
    refreshClientCampaignGroups().promise.then(() => {
      setSelectedClientCampaignGroup(clientCampaignGroup)
      setEditingClientCampaignGroup(null)
    })
  ), [refreshClientCampaignGroups, setSelectedClientCampaignGroup, setEditingClientCampaignGroup])

  return (
    <MultiStepModal
      onClose={handleClose}
      showBackButton={editingClientCampaignGroup}
      onClickBackButton={handleClickBack}
      scroll='paper'
      modalTitle={title}
    >
        {editingClientCampaignGroup && (
            <ClientCampaignGroupForm
              contract={contract}
              clientCampaignsResource={clientCampaignsResource}
              clientCampaignGroup={editingClientCampaignGroup}
              onSaveOrDelete={handleSaveOrDelete}
              onCancel={handleClickBack}
            />
          ) || (
            <DailyCapModalBody
              dailyCapsCalendarResource={dailyCapsCalendarResource}
              clientCampaignGroupsResource={clientCampaignGroupsResource}
              selectedClientCampaignGroup={selectedClientCampaignGroup}
              setSelectedClientCampaignGroup={setSelectedClientCampaignGroup}
              dailyCapsResource={dailyCapsResource}
              setDailyCapsResource={setDailyCapsResource}
              campaignCaps={campaignCaps}
              onClickEdit={handleClickEdit}
              onClickAdd={handleClickAdd}
              formDirty={formDirty}
              setFormDirty={setFormDirty}
              onClose={onClose}
            />
          )}
    </MultiStepModal>
  )
}
