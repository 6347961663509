import moment from '../lib/moment'
import { getRemoteId } from '../lib/DataModel'

import PromisedResource from './PromisedResource'

function getOptions(vendor, year, month, timezone) {
  return {
    sources: {
      remote: {
        settings: {
          params: {
            timezone: timezone,
            year: year,
            month: month,
            ...(vendor ? {vendor_id: getRemoteId(vendor)} : {})
          }
        }
      }
    }
  }
}

function getPromise(remote, clientId, vendor, contract, year, month, timezone) {
  return remote.query(
    q => clientId ? q.findRelatedRecords({type: 'client', id: clientId}, 'dailyCounts') : contract ? q.findRelatedRecords(contract, 'dailyCounts') : q.findRecords('dailyCount'),
    getOptions(vendor, year, month, timezone)
  )
}

export default class DailyCountsResource extends PromisedResource {
  constructor({remote, clientId = '', contract = null, vendor = null, year = null, month = null, timezone}) {
    let currentDay
    if(year && month) {
      currentDay = moment([year, month-1]).endOf('month').date()
    } else {
      const now = moment.tz(timezone)
      year = now.year()
      month = now.month() + 1
      currentDay = now.date()
    }
    super(getPromise(remote, clientId, vendor, contract, year, month, timezone))
    this.clientId = clientId
    this.vendor = vendor
    this.year = year
    this.month = month
    this.currentDay = currentDay
  }
}
